import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/',
      name: 'MainView',
      component: () => import("@/layout/IntroPage.vue"),
      children: [
        // {
        //   path: "/",
        //   name: "LandingView",
        //   component: () => import("@/views/LandingView.vue")
        // },
        {
          path: "/",
          name: "MainView",
          component: () => import("@/views/MainView.vue")
        }
      ]
    },
    {
      path: '/world',
      name: 'WorldView',
      component: () => import("@/layout/worldPage.vue"),
      children: [
        {
          path: "/world/lobby",
          name: "LobbyView",
          component: () => import("@/views/LobbyView.vue")
        },
      ]
    },
    {
      path: '/test',
      name: 'TestView',
      component: () => import("@/views/TestView.vue")
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
export default router