<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
body {
    overflow: hidden;
    font-family: 'AppleSDGothicNeo' !important;
    height: 100%;
}
#app {
  font-family: 'AppleSDGothicNeo' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  overflow: hidden;
}
@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 100;
    src: url("/font/AppleSDGothicNeoT.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 200;
    src: url("/font/AppleSDGothicNeoUL.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 300;
    src: url("/font/AppleSDGothicNeoL.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 400;
    src: url("/font/AppleSDGothicNeoR.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 500;
    src: url("/font/AppleSDGothicNeoM.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 600;
    src: url("/font/AppleSDGothicNeoSB.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 700;
    src: url("/font/AppleSDGothicNeoB.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 800;
    src: url("/font/AppleSDGothicNeoEB.woff2") format("woff2");
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-weight: 900;
    src: url('/font/AppleSDGothicNeoH.woff2') format("woff2");
}
</style>
