import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import store from './store/store';
import VuePdf from 'vue3-pdfjs'
import VueGtag from "vue-gtag-next";
import { trackRouter } from "vue-gtag-next";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VuePdf);
app.use(VueGtag, {
    property: {
      id: "G-F07PSRQCT1"
    }
});
trackRouter(router);

app.mount('#app');
